/* src/Accordion.css */
.accordion {
    width: 300px;
    border: 1px solid #ccc;
    border-radius: 5px;
    overflow: hidden;
  }
  
  .accordion-item {
    border-top: 1px solid #ccc;
  }
  
  .accordion-item:first-child {
    border-top: none;
  }
  
  .accordion-title {
    padding: 10px;
    background-color: #f7f7f7;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .accordion-content {
    padding: 10px;
    background-color: #e07878;
  }
  
  .first-info{
     width:540px;
     background-color: var(--bg1);
  }
  header{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px;
  }
  .description{
    background-color: #e9e7e7;
    padding: 0px 10px;
    overflow: hidden;
    line-height: 0px;
    transition: all 0.6s ease-in-out;
  }
  .opentab{
    line-height: 40px;
    padding: 10px 0px;
  }
  .first-info:nth-child(1){
    background-color: rgb(112, 229, 112);
 }
 .first-info:nth-child(2){
    background-color: rgb(229, 50, 50);
 }
 .first-info:nth-child(3){
    background-color: rgb(134, 134, 231);
 }





.recommendation {
  border: 1px solid #ccc;
  padding: 15px;
  margin-bottom: 20px;
  border-radius: 5px;
  background-color: #f9f9f9;
}

.suggestion-line {
  margin: 0;
  padding: 5px 0;
}

.bold-text {
 
  color: #000; /* Example color for the bold text */
  display: block; /* Ensures it appears on its own line */
}

.line-color-0,
.line-color-1,
.line-color-2,
.line-color-3,
.line-color-4,
.line-color-5,
.line-color-6,
.line-color-7,
.line-color-8,
.line-color-9,
.line-color-10,
.line-color-11,
.line-color-12,
.line-color-13  {
  display: block; /* Ensures each part after the colon appears on a new line */
}

/* Customizable line colors */
.line-color-0 {
  color: #01030e; /* Example color for the first line */
  font-size: x-large;
   font-family: 'fredoka'
}

.line-color-1 {
  color: #01030e; /* Example color for the second line */
  
  /* font-size: large; */
}

.line-color-2 {
  color: #01030e; /* Example color for the third line */
  /* font-size: large; */
  padding-left: 1rem;
}

.line-color-3 {
  color: #01030e; /* Example color for the fourth line */
  /* font-size: large; */
  padding-left: 1rem;
}

.line-color-4 {
  color: #01030e; /* Example color for the fifth line */
  /* font-size: large; */
  padding-left: 1rem;
}

.line-color-5 {
  color: #01030e; /* Example color for the sixth line */
  /* font-size: large; */
  padding-left: 1rem;
}

.line-color-6 {
  color: #01030e; /* Example color for the seventh line */
  /* font-size: larger; */
  padding-left: 1rem;
}

.line-color-7 {
  color: #01030e; /* Example color for the eighth line */
  padding-left: 1rem;
}

.line-color-8 {
  color: #01030e; /* Example color for the ninth line */
  padding-left: 1rem;
}

.line-color-9 {
  color: #01030e; /* Example color for the tenth line */
  padding-left: 1rem;
}

.line-color-10 {
  color: #01030e; /* Example color for the tenth line */
  padding-left: 1rem;
}

.line-color-11 {
  color: #01030e; /* Example color for the tenth line */
  padding-left: 1rem;
}
.line-color-12 {
  color: #01030e; /* Example color for the tenth line */
  padding-left: 1rem;
}
.line-color-13 {
  color: #01030e; /* Example color for the tenth line */
  padding-left: 1rem;
}