


  
  
  .roughShadow{
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  }
  
  
.mpi{
   box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;;
}

  /* logoutbutton */
  .logout-button {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #ff5a5f;
    color: white;
    border: none;
    border-radius: 9999px;
    padding: 10px 20px;
    font-size: 16px;
    font-weight: bold;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .logout-button:hover {
    background-color: #ff4146;
  }
  
  /* Icon Styles */
  
  
  .logout-icon {
    animation: rotateAnimation 2s infinite linear;
  }






 .colors{
 background-image: linear-gradient(#74b7fd, blue)}