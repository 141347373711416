

.button{
    position: relative;
    width: 200px;
    height: 170px;
   display: flex;
   justify-content: center;
   align-items: center;
   overflow: hidden;
   background-color: rgb(0, 0, 0);
   border-radius: 20px;
}
.button::before{
content: "";
position: absolute;
width: 180px;
height: 140px;
background-color: white;
box-shadow:0 0 20px rgb(8, 8, 8) ;
animation: animate 4s linear infinite;

}

.button::after{
   content: "";
   position: absolute;
  inset: 10px; 
   background-color: rgb(4, 75, 97);
   border-radius: 16px;
}
@keyframes animate{
    0%{
        transform: rotate(0deg);
    }
    100%{
        transform: rotate(360deg);
    }
}