
.side-main{
    width: 210px;
    display: flex;
    flex-direction: column;
    justify-content: center; 

    padding: 10px;
    margin: auto;
    align-items: start; 
    height: 100vh;
}


.menu-item {
    display: flex;
    align-items: center;
    padding: 5px;
    margin-top: 15px;
    cursor: pointer;
    transition: background-color 0.3s; 
    position: relative;
    display: flex;
    align-items: center;
    padding: 8px;
    border: none;  

}
.menu-item2{
    display: flex;
    align-items: center;
    padding: 5px;
    margin-top: 15px;
    cursor: pointer;
    transition: background-color 0.3s; 
    position: relative;
    display: flex;
    align-items: center;
    padding: 8px;
    border: none; 
}
.menu-item2:hover {
    color: white; 
    background-image: linear-gradient(to right, #092867,#1F52B9); 
    cursor: pointer; 
   
}
.col :hover{
    background-image: linear-gradient(to right, #092867,#1F52B9);
    
}
  .menu-item:hover {
    color: white; 
    background-image: linear-gradient(to right, #092867,#1F52B9); 
    cursor: pointer;  
}
  
  .menu-item img {
    transition: filter 0.3s; 
  }
  
  .menu-item:hover img {
    filter: brightness(0) invert(1); 
  }
  
  .menu-item p {
    margin-left: 10px;
  }
  .col :hover{
   background-color: #1F52B9;
   color: black;
  }
  .col{
    color: black;
    background-color: white;
  }
  #per :hover{
    background-image: linear-gradient(to right, #092867,#1F52B9); 

  }