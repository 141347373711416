.circular-progress-container {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  /* .circular-progress-label {
    position: absolute;
    transform: translate(-50%, -50%);
    font-family: Arial, sans-serif;
    text-align: center;
    
    font-weight: bold;
    
  } */
  
  .circular-progress-bar {
    
    transform: rotate(-90deg);
  }
  
  .circular-progress-bar .rc-progress-circle-path {
    stroke: #5b0606; /* Background color */
  }
  
  .circular-progress-bar .rc-progress-circle-trail {
    stroke: #6a0808; /* Trail color */
  }
  
  .circular-progress-bar .rc-progress-circle-path {
    stroke: #3498db; /* Progress color */
  }


/* //LineChart Css */



  .Progress-Container {
    display: flex;
    flex-direction: column;
    align-items: center;
   
  }
  
  .horizontal-progress-Bar{
    width: 80%;
    margin-bottom: 10px;
    color: rgb(252, 15, 15);
  }
  
  .rc-progress-line {
    height: 10px;
  }
  
  .rc-progress-line-bg {
    background-color: #e60e88; /* Light gray background */
  }
  
  .rc-progress-line-path {
    background-color: #c3e70f; /* Blue progress color */
  }
  
  .percentage-label {
    font-family: Arial, sans-serif;
    font-size: 16px;
    font-weight: bold;
    
  }